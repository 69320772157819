import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public baseURL = environment.baseURL

  constructor(private httpClient: HttpClient) {}

  getOrdersBySearch(operator, searchQueries, offset?, itemsPerPage?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .get<any>(
          `${this.baseURL}/v1/orders?search=${searchQueries}operator:${operator}&&offset=${offset || 0}&&limit=${itemsPerPage || 10}`
        )
        .pipe(take(1))
        .subscribe(
          response => resolve(response),
          error => reject(error)
        )
    })
  }
  getOrders(offset?, itemsPerPage?): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .get<any>(`${this.baseURL}/v1/orders?offset=${offset || 0}&&limit=${itemsPerPage || 10}`)
        .pipe(take(1))
        .subscribe(
          response => resolve(response),
          error => reject(error)
        )
    })
  }
  getOrderById(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .get<any>(`${this.baseURL}/v1/orders/${id}`)
        .pipe(take(1))
        .subscribe(
          response => resolve(response),
          error => reject(error)
        )
    })
  }
}
