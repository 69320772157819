import { Component, OnInit } from '@angular/core'
import { Validators, FormGroup, FormBuilder } from '@angular/forms'
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar'
import { environment } from '../../../environments/environment'
import { Inquiry } from 'src/app/core/models/inquiry'
import { ContactUsService } from 'src/app/core/services/contact-us.service'
import { ROUTE_URLS } from '../../app-routing-names'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  public isLoading = false
  public name: string
  public email: string
  public message: string
  public phone: string
  public fullImgPath = environment.fullImgPath
  public ROUTE_URLS = ROUTE_URLS
  public contactUsForm: FormGroup
  userInfo: Inquiry = new Inquiry()
  public config = new MatSnackBarConfig()
  constructor(private formBuilder: FormBuilder, private contactUsService: ContactUsService, public snackBar: MatSnackBar) {
    this.contactUsForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      name: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]*$')])],
      phone: ['', Validators.compose([Validators.required])],
      message: ['', Validators.required],
    })
  }

  ngOnInit() {}
  public sendMessage(recaptchaRes) {
    if (recaptchaRes) {
      this.isLoading = true
      this.userInfo = {
        email: this.contactUsForm.get('email').value,
        name: this.contactUsForm.get('name').value,
        message: this.contactUsForm.get('message').value,
        phone: this.contactUsForm.get('phone').value,
      }
      this.config.horizontalPosition = 'center'
      this.config.verticalPosition = 'top'
      this.config.duration = 4000
      this.config.panelClass = ['bar-class']
      this.contactUsService.createInquiry(this.userInfo).then(
        res => {
          this.isLoading = false
          this.snackBar.open('Your message has been sent successfully! Thank you', '', this.config)
          this.contactUsForm.reset()
        },
        error => {
          this.snackBar.open('Oops!! something went wrong', '', this.config)
        }
      )
    }
  }
}
