<header class="header-container">
  <div class="header-flex-container">
    <img src="{{ fullImgPath }}ztar-logo.svg" alt="ztar-logo" class="header-logo" />
    <div class="search-selection">
      <form [formGroup]="searchForm" (ngSubmit)="search()">
        <div class="mat-fields">
          <mat-form-field appearance="fill" disableRipple>
            <mat-label>Category</mat-label>
            <mat-select formControlName="searchOptions" (ngModelChange)="selectOptionHandler($event)" id="Selection" required>
              <mat-option *ngFor="let opt of options" [value]="opt">{{ opt?.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" disableRipple>
            <mat-label>Enter search value</mat-label>
            <input formControlName="searchValue" matInput required autocomplete="off" />
          </mat-form-field>
        </div>
        <button type="submit" class="submit-button" [class.disabled]="!searchForm.valid">Submit</button>
        <div><button (click)="test()">Login</button></div>
      </form>
    </div>
  </div>
</header>
