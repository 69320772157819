import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ZendeskService } from 'src/app/core/services/zendesk.service'
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-zendesk',
  templateUrl: './zendesk.component.html',
  styleUrls: ['./zendesk.component.scss'],
})
export class ZendeskComponent implements OnInit {
  public successConfig = new MatSnackBarConfig()
  public errorConfig = new MatSnackBarConfig()
  public fileTicketForm: FormGroup
  public fullImgPath = environment.fullImgPath
  public isLoading = false
  public audienceOptions
  public categoryOptions
  public selectedAudience
  public selectedCategory
  public note
  public ticketBody
  public panelExpanded
  public showCreationIssueSection = false

  constructor(private formBuilder: FormBuilder, private zendeskService: ZendeskService, public snackBar: MatSnackBar) {
    this.fileTicketForm = formBuilder.group({
      selectedAudience: ['', Validators.required],
      selectedCategory: ['', Validators.required],
      note: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.audienceOptions = [
      { value: 'Customer', viewValue: 'Customer' },
      { value: 'Customer care', viewValue: 'Customer care' },
    ]
  }

  public selectAudienceOption($event) {
    this.selectedAudience = $event
    if (this.selectedAudience === 'Customer') {
      this.categoryOptions = [
        { value: 'Invalid Address', viewValue: 'Invalid Address' },
        { value: 'Returned Shipment', viewValue: 'Returned Shipment' },
      ]
    } else if (this.selectedAudience === 'Customer care') {
      this.categoryOptions = [
        { value: 'Inventory', viewValue: 'Inventory' },
        { value: 'Mailing Service', viewValue: 'Mailing Service' },
        { value: 'Website', viewValue: 'Website' },
        { value: 'Packaging', viewValue: 'Packaging' },
        { value: 'Equipment', viewValue: 'Equipment' },
      ]
    }
  }
  public selectCategoryOption($event) {
    this.selectedCategory = $event
  }
  public createIssue() {
    this.showCreationIssueSection = true
  }
  public cancelIssue() {
    this.showCreationIssueSection = false
    this.fileTicketForm.reset()
  }
  public fileAticket() {
    /* Success Bar */
    this.successConfig.horizontalPosition = 'center'
    this.successConfig.verticalPosition = 'top'
    this.successConfig.duration = 4000
    this.successConfig.panelClass = ['zendesk-success-bar-class']
    /* Error Bar */
    this.errorConfig.horizontalPosition = 'center'
    this.errorConfig.verticalPosition = 'top'
    this.errorConfig.duration = 4000
    this.errorConfig.panelClass = ['zendesk-error-bar-class']
    this.note = this.fileTicketForm.get('note').value
    this.isLoading = true
    this.ticketBody = {
      ticket: {
        subject: this.selectedCategory,
        comment: { body: this.note },
        agent: 'Lisa Korte',
        status: 'pending',
      },
    }
    if (!!this.fileTicketForm.valid) {
      this.zendeskService
        .createTicket(this.ticketBody)
        .then(result => {
          if (!!result) {
            this.isLoading = false
            this.snackBar.open('Your ticket has been created successfully!', '', this.successConfig)
            this.fileTicketForm.reset()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.snackBar.open('Oops!! something went wrong', '', this.errorConfig)
        })
    }
  }
}
