<section class="process" [class.loading-hidden-transparent]="!!loading">
  <div class="back-section">
    <div class="arrow">
      <a routerLink="/orders"><img src="{{ fullImgPath }}back-arrow.png" alt="Back"/></a>
    </div>
    <div class="title">
      <a routerLink="/orders">Back to Orders</a>
    </div>
  </div>
  <div class="dropdown show drop">
    <app-zendesk></app-zendesk>
  </div>

  <div class="order-section" *ngIf="!!orderDetails">
    <ng-container *ngIf="!!orderDetails?.shipmentInfo">
      <div class="shippment">
        <div class="shipping-details" [ngClass]="{ scrollable: orderSims.length > 2, 'scrollbar-primary': orderSims.length > 2 }">
          <section class="picking-section">
            <h5 class="brand">Picking Station</h5>
            <ng-container *ngIf="orderDetails?.orderStatusCode == 'AWAITING_FULFILLMENT' || !!editSims">
              <div class="sim-details" *ngFor="let sim of orderSims; let i = index">
                <h6 class="title">{{ sim?.title }}</h6>
                <div class="first-row">
                  <div class="sku">
                    <h6 class="title">SKU</h6>
                    <p class="info">{{ sim?.sku }}</p>
                  </div>
                  <div class="type">
                    <p class="sim-type" *ngIf="!!sim?.properties?.simType">{{ sim?.properties?.simType }}</p>
                    <p
                      class="order-type"
                      [class.replacement]="sim?.properties?.intent == 'replacement'"
                      [class.no-type]="!sim?.properties?.simType"
                    >
                      {{ sim?.properties?.intent == 'replacement' ? 'SIM Replacement' : 'New Purchase' }}
                    </p>
                  </div>
                </div>
                <div class="second-row">
                  <div class="iccid">
                    <input
                      [class.invalid]="sim.properties.invalid"
                      type="tel"
                      [(ngModel)]="sim.properties.iccid"
                      class="sim-input"
                      placeholder="SIM card #"
                      minlength="19"
                      maxlength="22"
                      name="iccid{{ i }}"
                      restrictNumbers
                      #simNumber="ngModel"
                      (input)="validateIccid(sim.properties.iccid, i)"
                    />
                    <label class="validation-message" *ngIf="sim.properties.invalid && simNumber.dirty && simNumber.value">
                      SIM number is between 19-22 digits</label
                    >
                    <label class="validation-message" *ngIf="sim.properties.required && simNumber.touched">SIM number is Required</label>
                  </div>
                </div>
              </div>
              <div class="action-row">
                <button
                  class="button primary"
                  type="button"
                  title="continue"
                  id="continue-picking-button"
                  [disabled]="!validSims"
                  [class.disabled]="!validSims"
                >
                  Continue
                </button>
                <button
                  class="button secondary"
                  type="button"
                  title="Save for later"
                  id="save-later-button"
                  [disabled]="!validPartialSims"
                  [class.disabled]="!validPartialSims"
                >
                  Save for later
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="orderDetails?.orderStatusCode == 'AWAITING_SHIPMENT' && !editSims">
              <div class="sim-info" [class.seperate]="orderSims.length > 1" *ngFor="let sim of orderSims; let i = index">
                <div class="info-row">
                  <h6 class="title">{{ sim?.title }}</h6>
                </div>
                <div class="info-row">
                  <h6 class="title">SKU</h6>
                  <p class="info">{{ sim?.sku }}</p>
                </div>
                <div class="info-row" *ngIf="!!sim?.properties?.simType">
                  <h6 class="title">Type</h6>
                  <p class="info">{{ sim?.properties?.simType }}</p>
                </div>
                <div class="info-row">
                  <h6 class="title">SIM card #</h6>
                  <p class="info">{{ sim?.properties?.iccid }}</p>
                </div>
                <div class="info-row">
                  <h6 class="title">Shipping method</h6>
                  <p class="info">{{ orderDetails?.shipmentInfo?.carrier.serviceCode }}</p>
                </div>
                <div class="info-row">
                  <h6 class="title">IMEI</h6>
                  <p class="info">{{ sim?.properties?.imei || sim?.properties?.meid }}</p>
                </div>
              </div>
              <div class="action-row">
                <button class="button primary" (click)="editPickingStation()" type="button" title="Edit" id="edit-picking-button">
                  Edit
                </button>
              </div>
            </ng-container>
          </section>
          <section class="shipping-station" *ngIf="orderDetails?.orderStatusCode == 'AWAITING_SHIPMENT'">
            <h5 class="brand">Shipping Station</h5>
            <div class="calculate-row">
              <h6 class="title">Weight</h6>
              <div class="lbs">
                <input
                  type="number"
                  class="weight-input"
                  [(ngModel)]="lbsWeight"
                  (input)="ozWeight = lbsWeight * 16"
                  [min]="minLbsWeight"
                />
                <span class="unit"> lbs.</span>
              </div>
              <div class="ounce">
                <input type="number" class="weight-input" [(ngModel)]="ozWeight" (input)="lbsWeight = ozWeight / 16" [min]="minOzWeight" />
                <span class="unit"> oz.</span>
              </div>
            </div>
            <p class="validation-message" *ngIf="ozWeight < minOzWeight || lbsWeight < minLbsWeight">Invalid Weight</p>
            <div class="info-row">
              <h6 class="title">Service</h6>
              <p class="info">
                <select class="dropdown disabled" disabled [(ngModel)]="shipmentService">
                  <option class="option" value="usps_priority_mail_express">USPS Priority Mail Express</option>
                </select>
              </p>
            </div>
            <div class="info-row">
              <h6 class="title">Tracking</h6>
              <p class="info">
                <select class="dropdown disabled" disabled [(ngModel)]="tracking">
                  <option class="option" value="signature_only">Signature Only</option>
                </select>
              </p>
            </div>
            <div class="action-row">
              <button
                class="button primary"
                (click)="generateLabel()"
                type="button"
                title="generate label"
                [disabled]="ozWeight < minOzWeight || lbsWeight < minLbsWeight"
                [class.disabled]="ozWeight < minOzWeight || lbsWeight < minLbsWeight"
                id="generate-label-button"
              >
                Generate Label
              </button>
            </div>
          </section>
        </div>
      </div>
    </ng-container>
    <section class="user-info-section scrollable scrollbar-primary">
      <h5 class="brand">{{ orderDetails?.mvnoName }}</h5>
      <div class="info-details">
        <div class="info-row">
          <div class="left">
            <h6 class="title copiable">
              First name
              <img class="copy" src="assets/img/copy.svg" alt="copy" (click)="copy(orderDetails?.contact?.firstName, 'first')" /><span
                class="success-message"
                *ngIf="copiedItem == 'first'"
                >Copied!</span
              >
            </h6>
            <p class="info">{{ orderDetails?.contact?.firstName }}</p>
          </div>
          <div class="right">
            <h6 class="title copiable">
              Last name
              <img class="copy" src="assets/img/copy.svg" alt="copy" (click)="copy(orderDetails?.contact?.lastName, 'last')" /><span
                class="success-message"
                *ngIf="copiedItem == 'last'"
                >Copied!</span
              >
            </h6>
            <p class="info">{{ orderDetails?.contact?.lastName }}</p>
          </div>
        </div>
        <div class="info-row">
          <div class="left" *ngIf="!!orderDetails?.shipmentInfo">
            <h6 class="title copiable">
              Address: <img class="copy" src="assets/img/copy.svg" alt="copy" (click)="copy(shippingAddress, 'address')" /><span
                class="success-message"
                *ngIf="copiedItem == 'address'"
                >Copied!</span
              >
            </h6>
            <p class="info address">{{ shippingAddress?.address1 }}</p>
            <p class="address info">{{ shippingAddress?.city }}, {{ shippingAddress?.state }} - {{ shippingAddress?.postalCode }}</p>
          </div>
          <div class="right">
            <h6 class="title copiable">
              Order ID <img class="copy" src="assets/img/copy.svg" alt="copy" (click)="copy(orderDetails?.id, 'id')" /><span
                class="success-message"
                *ngIf="copiedItem == 'id'"
                >Copied!</span
              >
            </h6>
            <p class="info order-id">{{ orderDetails?.id }}</p>
          </div>
        </div>
        <div class="info-row">
          <div class="left">
            <h6 class="title copiable">
              Email <img class="copy" src="assets/img/copy.svg" alt="copy" (click)="copy(orderDetails?.contact?.email, 'email')" /><span
                class="success-message"
                *ngIf="copiedItem == 'email'"
                >Copied!</span
              >
            </h6>
            <p class="info">{{ orderDetails?.contact?.email }}</p>
          </div>
          <div class="right">
            <h6 class="title">
              Date of order
            </h6>
            <p class="date">{{ orderDetails?.createdDate | date: 'MM-dd-yyyy' }}</p>
            <p class="date">{{ orderDetails?.createdDate | date: 'HH:MM a' }}</p>
          </div>
        </div>
        <div class="info-row">
          <div class="left">
            <h6 class="title">
              Order status
            </h6>
            <p class="info">{{ orderDetails?.orderStatusTitle | titlecase }}</p>
          </div>
          <div class="right">
            <h6 class="title">
              Type
            </h6>
            <p class="info">{{ orderDetails?.orderTypeTitle | titlecase }}</p>
          </div>
        </div>

        <h5 class="brand">Order details</h5>
        <ng-container *ngIf="!!orderNewPlans">
          <div class="plan-details" *ngFor="let plan of orderNewPlans">
            <div class="info-row">
              <h6 class="title">{{ plan?.title }}</h6>
            </div>
            <div class="info-row">
              <div class="left">
                <h6 class="title">Quantity ( {{ plan?.quantity }} )</h6>
              </div>
              <div class="right">
                <p class="info">{{ plan?.unitPrice * plan?.quantity | currency: 'USD' }}</p>
              </div>
            </div>
            <div class="info-row sub-info">
              <div class="left" *ngFor="let item of plan?.associatedItems">
                <h6 class="title">{{ item?.title }}</h6>
                <p class="info">
                  <b>{{ item?.categoryName == 'SIMs' ? 'SKU' : 'Code' }} </b>{{ item?.sku }}
                </p>
                <p class="info" *ngIf="item?.categoryName == 'SIMs'">
                  <b>{{ !!item?.properties?.imei ? 'IMEI' : 'MEID' }} </b>{{ item?.properties?.imei || item?.properties?.meid }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="orderNewPlans.length < 1">
          <div class="info-row">
            <h6 class="title mdn">Purchases for line #: {{ orderMdn }}</h6>
          </div>
          <ng-container *ngIf="!!renewalPlanOrder" class="sub-container">
            <div class="info-row">
              <div class="left">
                <h6 class="title">Plan Renewal</h6>
              </div>
            </div>
            <div class="info-row">
              <div class="left sub">
                <h6 class="title">{{ renewalPlanOrder?.title }}</h6>
              </div>
            </div>
            <div class="info-row fees">
              <div class="left sub">
                <h6 class="title">Quantity ( {{ renewalPlanOrder?.quantity }} )</h6>
              </div>
              <div class="right">
                <p class="info">{{ renewalPlanOrder?.unitPrice * renewalPlanOrder?.quantity | currency: 'USD' }}</p>
              </div>
            </div>
            <hr class="seperator" />
          </ng-container>
          <ng-container *ngIf="!!changePlanOrder" class="sub-container">
            <div class="info-row">
              <div class="left">
                <h6 class="title">Change Plan</h6>
              </div>
            </div>
            <div class="info-row">
              <div class="left sub">
                <h6 class="title">{{ changePlanOrder?.title }}</h6>
              </div>
            </div>
            <div class="info-row">
              <div class="left sub">
                <h6 class="title">Quantity ( {{ changePlanOrder?.quantity }} )</h6>
              </div>
              <div class="right">
                <p class="info">{{ changePlanOrder?.unitPrice * changePlanOrder?.quantity | currency: 'USD' }}</p>
              </div>
            </div>
            <hr class="seperator" />
          </ng-container>
          <ng-container *ngIf="!!addonOrder" class="sub-container">
            <div *ngFor="let addon of addonOrder">
              <div class="info-row">
                <div class="left">
                  <h6 class="title">Plan Addon</h6>
                </div>
              </div>
              <div class="info-row">
                <div class="left sub">
                  <h6 class="title">{{ addon?.title }}</h6>
                </div>
              </div>
              <div class="info-row">
                <div class="left sub">
                  <h6 class="title">Quantity ( {{ addon?.quantity }} )</h6>
                </div>
                <div class="right">
                  <p class="info">{{ addon?.unitPrice * addon?.quantity | currency: 'USD' }}</p>
                </div>
              </div>
            </div>
            <hr class="seperator" />
          </ng-container>
          <ng-container *ngIf="!!simReplacementOrder">
            <div class="info-row">
              <div class="left">
                <h6 class="title">SIM Replacement</h6>
              </div>
            </div>
            <div class="info-row">
              <div class="left sub">
                <h6 class="title">{{ simReplacementOrder?.title }}</h6>
              </div>
            </div>
            <div class="info-row">
              <div class="left sub">
                <h6 class="title">Quantity ( {{ simReplacementOrder?.quantity }} )</h6>
              </div>
              <div class="right">
                <p class="info">{{ simReplacementOrder?.unitPrice * simReplacementOrder?.quantity | currency: 'USD' }}</p>
              </div>
            </div>
            <hr class="seperator" />
          </ng-container>
        </ng-container>
        <div class="info-row fees">
          <div class="left">
            <h6 class="title">Surcharges fees</h6>
          </div>
          <div class="right">
            <p class="info">{{ orderDetails?.meta?.amounts?.totalFees | currency: 'USD' }}</p>
          </div>
        </div>
        <div class="info-row fees">
          <div class="left">
            <h6 class="title">Govt. taxes & fees</h6>
          </div>
          <div class="right">
            <p class="info">{{ orderDetails?.meta?.amounts?.totalTaxes | currency: 'USD' }}</p>
          </div>
        </div>
        <hr class="main-seperator" />
        <div class="info-row fees">
          <div class="left total-amount"></div>
          <div class="right">
            <h6 class="title">Total: {{ orderDetails?.meta?.amounts?.netAmount | currency: 'USD' }}</h6>
          </div>
        </div>
        <hr class="main-seperator" />
      </div>
    </section>
  </div>
</section>
