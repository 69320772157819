import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  public baseURL = environment.baseURL

  constructor(private httpClient: HttpClient) {}

  createTicket(body): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post<any>(`${this.baseURL}/v1/zendesk`, body)
        .pipe(take(1))
        .subscribe(
          response => resolve(response),
          error => reject(error)
        )
    })
  }
}
