import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { IAutoCompleteAddressComponents, IAddress } from 'src/app/core/models/index'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'

@Injectable({
  providedIn: 'root',
})
export class AutoCompleteAddress {
  public observer = new ReplaySubject<IAddress>(1)
  public sharedData = this.observer.asObservable()
  public findDetailedAddressFields(data): Observable<IAddress> {
    if (!!data) {
      this._getAddressDetails(data).subscribe(addressDetails => {
        if (!!addressDetails) {
          const country = this._getAddressComponentByType(addressDetails, 'country', true)
          let state = this._getAddressComponentByType(addressDetails, 'administrative_area_level_1', true)
          if (country === 'PR') {
            // Paurto Rico
            state = country
          }
          let city = this._getAddressComponentByType(addressDetails, 'locality')
          if (city === '') {
            // test case for this try look up for '3738 Park Avenue'
            city = this._getAddressComponentByType(addressDetails, 'sublocality')
          }

          const fullStreet = (
            this._getAddressComponentByType(addressDetails, 'street_number', true) +
            ' ' +
            this._getAddressComponentByType(addressDetails, 'route', true)
          ).trim()
          const address: IAddress = {
            address1: fullStreet,
            address2: '',
            country: this._getAddressComponentByType(addressDetails, 'country'),
            city,
            state,
            postalCode: this._getAddressComponentByType(addressDetails, 'postal_code'),
          } as IAddress
          this.observer.next(address)
        } else {
          this.observer.error(`No details for this data : ${data}`)
        }
      })
    } else {
      console.warn('data is undefined')
    }
    return this.observer
  }
  private _getAddressDetails(data): Observable<any> {
    data.map(
      obj =>
        ({
          longName: obj.longName,
          shortName: obj.shortName,
          types: obj.types,
        } as IAutoCompleteAddressComponents)
    )
    return Observable.of(data)
  }
  private _getAddressComponentByType(addressComponents: Array<IAutoCompleteAddressComponents>, type: string, short?: boolean): string {
    let selectedComponentText = ''
    const selectedComponent = addressComponents.find(ac => {
      return ac.types.indexOf(type.toUpperCase()) !== -1
    })
    if (!!selectedComponent) {
      if (short) {
        selectedComponentText = selectedComponent.shortName
      } else {
        selectedComponentText = selectedComponent.longName
      }
    } else {
      selectedComponentText = '' // Failed to find
    }

    return selectedComponentText
  }
}
