<div class="history-section">
  <div [class.loading-hidden-transparent]="!!loading">
    <div class="table-content">
      <div class="table responsive-table">
        <table>
          <thead>
            <tr>
              <th>Brand</th>
              <th>Order ID</th>
              <th>Customer ID</th>
              <th>Data & Time</th>
              <th>Status</th>
              <th>Shipping Method</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of dataSource?.filteredData | paginate: config; trackBy: trackByOrder">
              <td>{{ order.mvnoName }}</td>
              <td>{{ order.id }}</td>
              <td>{{ order.customerId }}</td>
              <td>{{ order.createdDate | date: 'MM-dd-yy' }}<br />{{ order.createdDate | date: 'HH:MM a' }}</td>
              <td
                [ngClass]="{
                  'new-color': order.orderStatusCode == 'AWAITING_PAYMENT',
                  'pending-color': order.orderStatusCode == 'AWAITING_FULFILLMENT',
                  'processed-color': order.orderStatusCode == 'AWAITING_SHIPMENT',
                  'digital-color': order.orderStatusCode == 'SVC_PURCHASED'
                }"
              >
                {{ order?.orderStatusTitle | titlecase }}
              </td>
              <td>{{ order.shipmentMethod }}</td>
              <td>
                <button
                  class="process-button"
                  (click)="viewDetails(order.id, order.shipmentMethod)"
                  [ngClass]="{ active: buttonClickedId === order.id }"
                >
                  View Details
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination" *ngIf="dataSource?.filteredData?.length > 0">
          <app-pagination [config]="config" (configChange)="pageChanged($event)"></app-pagination>
        </div>
      </div>
      <app-order-details
        *ngIf="!!showDetails && !!historyDetails"
        [historyDetails]="historyDetails"
        [historyItems]="historyItems"
        [shippingMethod]="orderShippingMethod"
        (showDetails)="showDetails = $event"
        (buttonClickedId)="buttonClickedId = $event"
      ></app-order-details>
    </div>
  </div>
</div>
