import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule, routingComponents } from './app-routing.module'
import { AppComponent } from './app.component'
import { Globals } from './globals'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SharedModule } from './shared/shared.module'
import { HttpClientModule } from '@angular/common/http'
import { NgxPaginationModule } from 'ngx-pagination'
import { ClipboardModule } from 'ngx-clipboard'
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha'
import { PaginationComponent } from './component/pagination/pagination.component'
import { HeaderComponent } from './component/header/header.component'
import { MainNavBarComponent } from './component/main-nav-bar/main-nav-bar.component'
import { DatePickerComponent } from './component/date-picker/date-picker.component'
import { OrderService } from './core/services/order.service'
import { ContactUsService } from './core/services/contact-us.service'
import { environment } from '../environments/environment'
import { OrderDetailsComponent } from './component/order-details/order-details.component'
import { ZendeskComponent } from './component/zendesk/zendesk.component'
import { LoginComponent } from './component/login/login.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainNavBarComponent,
    routingComponents,
    PaginationComponent,
    DatePickerComponent,
    OrderDetailsComponent,
    ZendeskComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgxPaginationModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ClipboardModule,
  ],
  providers: [
    Globals,
    OrderService,
    ContactUsService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
