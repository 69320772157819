import { Component, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { OrderService } from '../../core/services/order.service'
import { ActivatedRoute } from '@angular/router'
import { ClipboardService } from 'ngx-clipboard'
import { AutoCompleteAddress } from 'src/app/autoCompleteAddress.service'

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
})
export class ProcessComponent implements OnInit {
  id: any
  public orderDetails
  public loading
  public fullImgPath = environment.fullImgPath
  public orderSims
  public shippingAddress
  public orderNewPlans = []
  public orderMdn
  public simReplacementOrder
  public changePlanOrder
  public renewalPlanOrder
  public addonOrder
  public validSims = false
  public validPartialSims = false
  public editSims = false
  public lbsWeight = 0
  public ozWeight = 0
  public minLbsWeight = 0
  public minOzWeight = 0
  public shipmentService = 'usps_priority_mail_express'
  public tracking = 'signature_only'
  public copiedItem = ''

  constructor(
    private _route: ActivatedRoute,
    private _orderService: OrderService,
    private _autoCompleteAddress: AutoCompleteAddress,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.id = this._route.snapshot.params.id
    this.getOrderDetails()
    this._autoCompleteAddress.sharedData.subscribe(address => {
      this.shippingAddress = address
    })
  }

  public copy(text: string, type?: string) {
    if (type === 'address') {
      const address =
        this.shippingAddress.address1 +
        ' ' +
        this.shippingAddress.address2 +
        '\n' +
        this.shippingAddress.city +
        ', ' +
        this.shippingAddress.state +
        ' - ' +
        this.shippingAddress.postalCode
      this._clipboardService.copyFromContent(address)
    } else {
      this._clipboardService.copyFromContent(text)
    }
    this.copiedItem = type
    setTimeout(() => {
      this.copiedItem = ''
    }, 800)
  }
  public validateIccid(iccid, index) {
    if (!iccid) {
      this.orderSims[index].properties.required = true
    } else {
      this.orderSims[index].properties.required = false
      this.orderSims[index].properties.invalid = !/^\d{19,22}?$/.test(iccid)
    }
    this._validateAllSimNumbers()
  }

  public editPickingStation() {
    this.editSims = true
    this.orderSims.map(sim => {
      sim.properties.invalid = false
      sim.properties.required = false
    })
    this.validSims = true
  }

  // TODO: implement generate label call
  public generateLabel() {}

  getOrderDetails() {
    this.loading = true
    this._orderService
      .getOrderById(this.id)
      .then(data => {
        if (!!data) {
          this.loading = false
          this.orderDetails = data
          this.orderDetails.items = this._convertPropertiesIntoObject(this.orderDetails.items)
          this.orderNewPlans = this.orderDetails.items.filter(item => item.categoryName === 'Plans' && item.properties.intent === 'new')
          if (!!this.orderDetails.shipmentInfo) {
            this._autoCompleteAddress.findDetailedAddressFields(data.shipmentInfo.shipTo.components)
            this.orderSims = this.orderDetails.items.filter(item => item.categoryName === 'SIMs')
            this.orderSims.map(sim => {
              sim.properties.required = true
              sim.properties.invalid = true
            })
            this.ozWeight = this.orderSims.length // each sim is 1 ounce weight
            this.lbsWeight = this.ozWeight / 16
            this.minLbsWeight = this.lbsWeight
            this.minOzWeight = this.ozWeight
          }
          if (!!this.orderNewPlans && this.orderNewPlans.length > 0) {
            this._getNewPlanAssociatedItems()
          } else {
            this._filterMdnItems()
          }
        }
      })
      .catch(error => {
        this.loading = false
        console.error(error)
      })
  }

  public updateWeight() {
    this.ozWeight = this.lbsWeight * 16
    this.lbsWeight = this.ozWeight / 16
  }

  /**
   * a function that filters the mdn order items by type
   */
  private _filterMdnItems() {
    this.orderMdn = this.orderDetails.items[0].properties.mdn
    this.renewalPlanOrder = this.orderDetails.items.find(item => item.categoryName === 'Plans' && item.properties.intent === 'replacement')
    this.changePlanOrder = this.orderDetails.items.find(item => item.categoryName === 'Plans' && item.properties.intent === 'change')
    this.addonOrder = this.orderDetails.items.filter(item => item.categoryName === 'Add-Ons')
    this.simReplacementOrder = this.orderDetails.items.find(
      item => item.categoryName === 'SIMs' && item.properties.intent === 'replacement'
    )
  }
  /**
   * a function that gets all new plan associated items and combines them in an array that is added to the plan item object
   */
  private _getNewPlanAssociatedItems() {
    this.orderNewPlans.map(item => {
      item.associatedItems = []
      item.associatedItems = this.orderSims.filter(sim => sim.properties.associatedWith === item.properties.associationCode)
      if (!!item.properties.activationCode) {
        item.properties.activationCode = item.properties.activationCode.split(',')
        item.properties.activationCode.map(code => {
          item.associatedItems.push({ title: 'Activation Code', sku: code })
        })
      }
    })
  }
  /**
   * a function that converts the item properties array into object
   * example [{name: type, value: sim}, {name: id, value: 123}] into {type: sim, id: 123}t
   */
  private _convertPropertiesIntoObject(array) {
    array = array.map(item => {
      const obj = item.properties.reduce((previousValue, currentValue, index) => {
        previousValue[currentValue.name] = currentValue.value
        if (!previousValue.iccid) {
          previousValue.iccid = '' // add iccid property to each SIM to save the user input
        }
        return previousValue
      }, {})
      item.properties = obj
      return item
    })
    return array
  }

  /**
   * a function that validates that all sim numbers are entered and valid
   */
  private _validateAllSimNumbers() {
    this.validSims = this.orderSims.every(sim => !sim.properties.invalid && !sim.properties.required)
    this.validPartialSims = this.orderSims.every(
      sim => (!sim.properties.invalid && !sim.properties.required) || (sim.properties.invalid && sim.properties.required)
    )
  }
}
