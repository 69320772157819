<div class="main-container">
  <div class="items-container">
    <!-- <a class="items top-border">Dashboard</a> -->
    <a class="items top-border" routerLink="/orders" routerLinkActive="is-active">Orders</a>
    <a routerLink="/history" routerLinkActive="is-active" class="items">History</a>
    <!-- <a class="items">Reports</a>
    <a class="items">Settings</a> -->
    <a routerLink="/search" routerLinkActive="is-active" class="items">Search</a>

    <div *ngIf="!!displayDatePicker" class="flex-container top displayPick">
      <app-date-picker></app-date-picker>
    </div>
  </div>
</div>
