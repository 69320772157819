import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { PaginationInstance } from 'ngx-pagination'
import { OrderService } from 'src/app/core/services/order.service'
import { MatTableDataSource } from '@angular/material/table'
import { environment } from 'src/environments/environment'
import { SearchService } from 'src/app/search.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public fullImgPath = environment.fullImgPath
  public orderInfo
  public loading
  public dataSource
  public category
  public name
  public searchValue
  public searchRequested
  public exactMatch = false
  public operator = 'or'
  public searchParams = ''
  public filteredOptions = []
  public config: PaginationInstance = {
    id: 'orders',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  }
  public paginationOffset = 0
  constructor(private route: ActivatedRoute, private router: Router, private orderService: OrderService, private data: SearchService) {}

  ngOnInit() {
    this.onload()
    this.data.sharedData.subscribe(data => {
      if (!!data.category && !!data.searchValue && !!data.name) {
        this.searchRequested = true
        this.category = data.category
        this.searchValue = data.searchValue
        this.name = data.name
        this.searchValue = this.searchValue.trim()
        if (data.category !== 'createdDateMin') {
          this.searchValue = this.searchValue.toLowerCase()
        }
        if (!this.filteredOptions.some(obj => obj.value === this.searchValue)) {
          this.filteredOptions.push({ category: this.category, value: this.searchValue, name: this.name })
          sessionStorage.setItem('filteredArray', JSON.stringify(this.filteredOptions))
        }
        this.searchResult(this.operator, this.filteredOptions, this.paginationOffset, this.config.itemsPerPage)
      } else {
        this.filteredOptions = []
      }
    })
  }
  public exactMatchChanged(changed) {
    this.exactMatch = changed
    if (this.exactMatch === true) {
      this.operator = 'and'
    } else {
      this.operator = 'or'
    }
    sessionStorage.setItem('exactMatch', JSON.stringify(this.exactMatch))
    this.searchResult(this.operator, this.filteredOptions, this.paginationOffset, this.config.itemsPerPage)
  }
  public searchResult(operator, options, offset, itemsPerPage) {
    this.loading = true
    this.searchParams = ''
    options.forEach(element => {
      this.searchParams = this.searchParams + `${element.category}:${element.value},`
    })
    this.orderService
      .getOrdersBySearch(operator, this.searchParams, offset, itemsPerPage)
      .then(data => {
        this.loading = false
        this.orderInfo = data.orders
        this.config.totalItems = data.meta.totalCount
        this.dataSource = new MatTableDataSource(data.orders)
      })
      .catch(error => {
        this.loading = false
        console.error(error)
      })
  }
  public removeItem(index) {
    this.filteredOptions.splice(index, 1)
    sessionStorage.setItem('filteredArray', JSON.stringify(this.filteredOptions))
    this.filteredOptions = JSON.parse(sessionStorage.getItem('filteredArray'))
    if (this.filteredOptions.length > 0) {
      this.searchResult(this.operator, this.filteredOptions, this.paginationOffset, this.config.itemsPerPage)
    } else {
      this.searchRequested = false
      this.searchParams = ''
    }
  }
  public pageChanged(page: number) {
    if (page > 0 && page <= this.orderInfo.length) {
      const index = page - 1
      this.config.currentPage = page
      const offset = index * this.config.itemsPerPage
      this.searchResult(this.operator, this.filteredOptions, offset, this.config.itemsPerPage)
    }
  }
  onload() {
    if (sessionStorage.getItem('filteredArray')) {
      this.filteredOptions = JSON.parse(sessionStorage.getItem('filteredArray'))
      this.searchRequested = true
      this.exactMatch = JSON.parse(sessionStorage.getItem('exactMatch'))
      if (this.exactMatch === true) {
        this.operator = 'and'
      }
      if (this.filteredOptions.length > 0) {
        this.searchResult(this.operator, this.filteredOptions, this.paginationOffset, this.config.itemsPerPage)
      }
    } else {
      this.filteredOptions = []
    }
    if (this.filteredOptions.length === 0) {
      this.searchRequested = false
      this.exactMatch = false
      this.operator = 'or'
      this.searchParams = ''
    }
  }
}
