import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { PaginationControlsDirective, PaginationInstance } from 'ngx-pagination'

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() config: PaginationInstance
  @Output() configChange: EventEmitter<number> = new EventEmitter()
  @ViewChild('paginationApi') paginationApi: PaginationControlsDirective

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.config) {
      this.config = changes.config.currentValue
    }
  }

  public setCurrentPage(pageNumber) {
    this.config.currentPage = pageNumber
    this.configChange.emit(pageNumber)
  }

  public previousPage(isFirstPage) {
    if (!isFirstPage) {
      this.setCurrentPage(--this.config.currentPage)
    }
  }

  public nextPage(lastPage) {
    if (!lastPage) {
      this.setCurrentPage(++this.config.currentPage)
    }
  }
}
