import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public baseURL = environment.baseURL

  constructor(private httpClient: HttpClient) {}

  login(): Promise<any> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')

    const options = {
      headers: httpHeaders,
    }

    return new Promise<any>((resolve, reject) => {
      window.location.href = `${this.baseURL}/v1/auth/login`
      // this.httpClient.get<any>(`${this.baseURL}/v1/auth/login`, options).subscribe(
      //   response => resolve(response),
      //   error => reject(error)
      // )
    })
  }

  forgetPass(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .get<any>(
          'https://localhost:8080/v1/auth/login'
          // `${this.baseURL}/v1/orders?search=${searchQueries}operator:${operator}&&offset=${offset || 0}&&limit=${itemsPerPage || 10}`
        )
        // .pipe(take(1))
        .subscribe(
          response => resolve(response),
          error => reject(error)
        )
    })
  }
}
