import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { environment } from 'src/environments/environment'
import { ROUTE_URLS } from 'src/app/app-routing-names'
import { Router } from '@angular/router'
import { AutoCompleteAddress } from 'src/app/autoCompleteAddress.service'
import { Location } from '@angular/common'

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @Input() historyDetails
  @Input() historyItems
  @Input() shippingMethod
  @Output() showDetails = new EventEmitter<boolean>()
  @Output() buttonClickedId = new EventEmitter<string>()
  public fullImgPath = environment.fullImgPath
  public shippingAddress
  constructor(private route: Router, private autoCompleteAddress: AutoCompleteAddress, private location: Location) {}

  ngOnInit(): void {
    console.log('history details', this.historyDetails)
    this.autoCompleteAddress.sharedData.subscribe(address => {
      this.shippingAddress = address
    })
  }
  public exitDetails() {
    this.showDetails.emit(false)
    this.buttonClickedId.emit('')
    this.location.go(ROUTE_URLS.HISTORY)
    sessionStorage.removeItem('shipment method')
  }
}
