import { Component, OnInit } from '@angular/core'
import { PaginationInstance } from 'ngx-pagination'
import { OrderService } from '../../core/services/order.service'
import { Router } from '@angular/router'
import { Order } from 'src/app/core/models/index'
import { MatTableDataSource } from '@angular/material/table'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  public dataSource
  public orderInfo
  public loading
  public config: PaginationInstance = {
    id: 'orders',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  }
  public paginationOffset = 0

  constructor(private router: Router, private orderService: OrderService) {}
  ngOnInit() {
    this.getOrdersPerPage(this.paginationOffset, this.config.itemsPerPage)
  }
  public pageChanged(page: number) {
    if (page > 0 && page <= this.orderInfo.length) {
      const index = page - 1
      this.config.currentPage = page
      const offset = index * this.config.itemsPerPage
      this.getOrdersPerPage(offset, this.config.itemsPerPage)
    }
  }
  public trackByOrder(index: number, order: Order) {
    return order.id
  }

  public getOrdersPerPage(offset, itemsPerPage) {
    this.loading = true
    this.orderService
      .getOrders(offset, itemsPerPage)
      .then(data => {
        this.loading = false
        this.orderInfo = data.orders
        this.config.totalItems = data.meta.totalCount
        this.dataSource = new MatTableDataSource(data.orders)
      })
      .catch(error => {
        this.loading = false
        console.error(error)
      })
  }
}
