import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { OrdersComponent } from '../app/component/orders/orders.component'
import { HistoryComponent } from '../app/component/history/history.component'
import { ProcessComponent } from '../app/component/process/process.component'
import { ContactUsComponent } from '../app/component/contact-us/contact-us.component'
import { SearchComponent } from './component/search/search.component'
import { ROUTE_URLS } from './app-routing-names'
import { LoginComponent } from './component/login/login.component'

const routes: Routes = [
  // { path: '', component: LoginComponent },
  { path: ROUTE_URLS.LOGIN, component: LoginComponent },
  { path: ROUTE_URLS.ORDERS, component: OrdersComponent },
  { path: ROUTE_URLS.HISTORY, component: HistoryComponent },
  { path: ROUTE_URLS.PROCESS, component: ProcessComponent },
  { path: ROUTE_URLS.CONTACTUS, component: ContactUsComponent },
  { path: ROUTE_URLS.HISTORY_DETAILS, component: HistoryComponent },
  { path: ROUTE_URLS.SEARCH, component: SearchComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [OrdersComponent, HistoryComponent, ContactUsComponent, ProcessComponent, SearchComponent, LoginComponent]
