import { Component, OnInit } from '@angular/core'
import { PaginationInstance } from 'ngx-pagination'
import { OrderService } from '../../core/services/order.service'
import { Router, ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'
import { ROUTE_URLS } from '../../app-routing-names'
import { Order } from 'src/app/core/models/index'
import { MatTableDataSource } from '@angular/material/table'
import { AutoCompleteAddress } from 'src/app/autoCompleteAddress.service'
import { Location } from '@angular/common'

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  public dataSource
  public id: string
  public orderDetails
  public historyDetails
  public historyItems
  public showDetails = false
  public fullImgPath = environment.fullImgPath
  public orderShippingMethod
  public loading
  public buttonClickedId
  public config: PaginationInstance = {
    id: 'orders',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  }
  constructor(
    private router: ActivatedRoute,
    private orderService: OrderService,
    private autoCompleteAddress: AutoCompleteAddress,
    private route: Router,
    private location: Location
  ) {}

  ngOnInit() {
    this.getAllOrders()
    this.router.paramMap.subscribe(params => {
      this.id = params.get('id')
    })
    this.orderShippingMethod = sessionStorage.getItem('shipment method')
    if (!!this.id && !!this.orderShippingMethod) {
      this.viewDetails(this.id, this.orderShippingMethod)
    }
  }
  public getAllOrders() {
    this.loading = true
    this.orderService
      .getOrders()
      .then(data => {
        this.loading = false
        this.orderDetails = data.orders
        this.dataSource = new MatTableDataSource(data.orders)
      })
      .catch(error => {
        this.loading = false
        console.error(error)
      })
  }
  public pageChanged(page: number) {
    if (page > 0 && page <= this.orderDetails.length) {
      const index = page - 1
      this.config.currentPage = page
    }
  }
  public viewDetails(id, shipmentMethod) {
    this.showDetails = true
    this.buttonClickedId = id
    this.location.go(ROUTE_URLS.HISTORY + '/' + id)
    if (!!shipmentMethod) {
      this.orderShippingMethod = shipmentMethod
      sessionStorage.setItem('shipment method', this.orderShippingMethod)
    }
    this.orderService.getOrderById(id).then(data => {
      this.historyDetails = data
      this.historyItems = this._convertPropertiesIntoObject(this.historyDetails.items)
      this.autoCompleteAddress.findDetailedAddressFields(data.shipmentInfo.shipTo.components)
    })
  }
  public trackByOrder(index: number, order: Order) {
    return order.id
  }
  private _convertPropertiesIntoObject(array) {
    array = array.map(item => {
      const obj = item.properties.reduce((previousValue, currentValue, index) => {
        previousValue[currentValue.name] = currentValue.value
        if (item.categoryId === '4722' && !previousValue.iccid) {
          // temp sol category's == 4722 that means this of type sims
          previousValue.iccid = '' // add iccid property to each SIM to save the user input
        }
        return previousValue
      }, {})
      item.properties = obj
      return item
    })
    return array
  }
}
