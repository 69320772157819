import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inquiry } from 'src/app/core/models/inquiry'
@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  url = 'https://ab1581b1-2966-4d79-8d97-6604baef0b3b.mock.pstmn.io/api/v1/inquiries'
  constructor(private http: HttpClient) {}

  public createInquiry(inquiry: Inquiry): Promise<any> {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')

    const options = {
      headers: httpHeaders,
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.url, inquiry, options).subscribe(
        () => resolve(),
        error => reject()
      )
    })
  }
}
