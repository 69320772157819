import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { ROUTE_URLS } from '../../app-routing-names'
@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss'],
})
export class MainNavBarComponent implements OnInit {
  public href = ''
  public displayDatePicker = false
  constructor(private router: Router) {
    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        this.href = route.url.substr(1)
        switch (this.href) {
          case ROUTE_URLS.ORDERS: {
            this.displayDatePicker = true
            break
          }
          case ROUTE_URLS.HISTORY: {
            this.displayDatePicker = true
            break
          }
          case ROUTE_URLS.SEARCH: {
            this.displayDatePicker = true
            break
          }
        }
      }
    })
  }
  ngOnInit() {}
}
