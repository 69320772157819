import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import { ROUTE_URLS } from '../../app-routing-names'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public fullImgPath = environment.fullImgPath
  public ROUTE_URLS = ROUTE_URLS
  public email: string
  public password: string
  public loginForm: FormGroup
  constructor(private formBuilder: FormBuilder) {
    this.loginForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {}
}
