<mat-form-field class="picker-margins">
  <input
    matInput
    placeholder="Choose a date"
    [(ngModel)]="data"
    [satDatepicker]="picker"
    [value]="data"
    disabled
    (ngModelChange)="changeDate($event)"
  />
  <sat-datepicker #picker [disabled]="false" [rangeMode]="true"></sat-datepicker>
  <sat-datepicker-toggle [disabled]="false" matSuffix [for]="picker"></sat-datepicker-toggle>
</mat-form-field>
