import { Component, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Router, ActivatedRoute } from '@angular/router'
import { Options, SearchData } from '../../core/models/index'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { SearchService } from 'src/app/search.service'
import { AuthService } from '../../core/services/auth.service'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public fullImgPath = environment.fullImgPath
  public searchForm: FormGroup
  public searchValue
  public searchOptions
  public selectedOptionValue
  public selectedOptionName
  public options: Options[]
  public searchData: SearchData
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private data: SearchService,
    private authServices: AuthService
  ) {
    this.searchForm = formBuilder.group({
      searchValue: ['', Validators.compose([Validators.required])],
      searchOptions: [Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
    this.options = [
      { name: 'Brand', value: 'mvnoName' },
      { name: 'Order Id', value: 'id' },
      { name: 'Customer ID', value: 'customerId' },
      { name: 'Status', value: 'orderStatus' },
      { name: 'shipping Method', value: 'shippingInfo.carrierInfo.serviceCode' },
    ]
  }
  public selectOptionHandler(event: Options) {
    this.selectedOptionValue = event.value
    this.selectedOptionName = event.name
  }
  public resetForm() {
    this.searchForm.reset({
      searchValue: '',
      searchOptions: '',
    })
  }
  public search() {
    if (!!this.searchForm.valid && !!this.selectedOptionName) {
      this.searchValue = this.searchForm.get('searchValue').value
      this.searchData = {
        category: this.selectedOptionValue,
        name: this.selectedOptionName,
        searchValue: this.searchValue,
      }
      sessionStorage.setItem('searchData', JSON.stringify(this.searchData))
      this.data.updateSearchData(this.searchData)
      this.resetForm()
    }
  }

  public test() {
    this.authServices.login().then(data => {
      console.log(data)
    })
  }
}
