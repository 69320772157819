<nav aria-label="Page navigation example">
  <div class="pagination">
    <pagination-template
      #p="paginationApi"
      [id]="config.id"
      (pageChange)="setCurrentPage($event)"
    >
      <ul class="custom-pagination pagination">
        <li
          class="page-item"
          [class.disabled]="p.isFirstPage()"
          *ngIf="!!p.pages"
          (click)="previousPage(p.isFirstPage())"
        >
          <a class="page-link"> &laquo; Previous </a>
        </li>
        <li
          class="page-item"
          *ngFor="let page of p.pages"
          [class.active]="p.getCurrent() === page.value"
        >
          <a
            class="page-link"
            (click)="p.setCurrent(page.value)"
            *ngIf="p.getCurrent() !== page.value"
          >
            <span>{{ page.label }}</span>
          </a>
          <a class="page-link" *ngIf="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </a>
        </li>
        <li
          class="page-item"
          [class.disabled]="p.isLastPage()"
          *ngIf="!!p.pages"
          (click)="nextPage(p.isLastPage())"
        >
          <a class="page-link"> Next &raquo; </a>
        </li>
      </ul>
    </pagination-template>
  </div>
</nav>
