<div class="details-border scrollbar-primary">
  <div class="exit" (click)="exitDetails()">
    <img src="{{ fullImgPath }}exit.png" alt="Exit" />
  </div>
  <div class="order-details-section">
    <!-- zendesk selector -->
    <div class="order-shipping">
      <div class="col-1">
        <div class="brand">
          {{ historyDetails?.mvnoName }}
        </div>
        <div class="info">
          <h6 class="title">Name Surname</h6>
          <p>{{ historyDetails?.contact?.firstName }},&nbsp;{{ historyDetails?.contact?.lastName }}</p>
        </div>
        <div class="info">
          <h6 class="title">Address</h6>
          <p class="desc">
            {{ shippingAddress?.address1 }}<br />{{ shippingAddress?.city }},&nbsp;{{ shippingAddress?.state }} -
            {{ shippingAddress?.postalCode }}
          </p>
        </div>
        <div class="info">
          <h6 class="title">Email</h6>
          <p>{{ historyDetails?.contact?.email }}</p>
        </div>
        <div class="info">
          <h6 class="title">Order Status:</h6>
          <p class="desc">
            {{ historyDetails?.orderStatusCode | titlecase }} since {{ historyDetails?.lastModifiedDate | date: 'MM-dd-yy' }}
          </p>
        </div>
      </div>
      <div class="col-2">
        <div class="info">
          <h6 class="title">Order ID</h6>
          <p class="desc">{{ historyDetails?.id }}</p>
        </div>
        <div class="info">
          <h6 class="title">Date of order:</h6>
          <p>
            {{ historyDetails?.createdDate | date: 'MM-dd-yy' }}<br />
            {{ historyDetails?.createdDate | date: 'HH:MM a' }}
          </p>
        </div>
        <div class="info">
          <h6 class="title">Type:</h6>
          <p>{{ historyDetails?.orderTypeCode | titlecase }}</p>
        </div>
      </div>
    </div>
    <div class="cancellation-section">
      <div class="display">
        <button class="button-style">Cancel Order</button>
        <button class="button-style margin-20">Void Order</button>
      </div>
    </div>
    <div *ngIf="!!historyDetails?.items">
      <div class="order-card" *ngFor="let item of historyDetails?.items">
        <div class="order-details">
          <div class="first-section">
            <div class="brand">
              Order Details
            </div>
            <div class="info">
              <h6 class="item-title">{{ item.title }}</h6>
            </div>
            <div class="height">
              <h6 class="title">GSM SIM</h6>
              <h6 class="title">SKU<span> 83926458920648920</span></h6>
            </div>
          </div>
          <div class="second-section">
            <div class="info">
              <h6 class="title">Quantity: {{ item.quantity }}</h6>
              <h6 class="title">Price: {{ item.unitPrice | currency }}</h6>
            </div>
          </div>
        </div>
        <div class="stations" *ngIf="!!historyDetails?.shipmentInfo && !!historyItems">
          <div *ngFor="let property of historyItems">
            <div class="picking-station">
              <div class="brand">
                Picking Station
              </div>
              <div class="height">
                <h6 class="title">{{ property.title }}</h6>
                <h6 class="title">
                  {{ property?.categoryName == 'SIMs' ? 'SKU' : 'Code' }} <span>{{ property?.sku }}</span>
                </h6>
                <h6 class="title" *ngIf="!!property?.properties?.iccid">
                  SIM card #<span> {{ property?.properties?.iccid }}</span>
                </h6>
                <h6 class="title" *ngIf="item?.categoryName == 'SIMs'">
                  {{ !!property?.properties?.imei ? 'IMEI' : 'MEID' }}
                  <span>{{ property?.properties?.imei || property?.properties?.meid }}</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stations" *ngIf="!!historyDetails?.meta">
      <div class="meta-container">
        <div class="meta-flex">
          <h6 class="meta-title">Surcharges fees:</h6>
          <span class="meta-info">{{ historyDetails?.meta?.amounts?.totalFees | currency: 'USD' }}</span>
        </div>
        <div class="meta-flex">
          <h6 class="meta-title">Govt. taxes & fees:</h6>
          <span class="meta-info">{{ historyDetails?.meta?.amounts?.totalTaxes | currency: 'USD' }}</span>
        </div>
        <div class="meta-flex">
          <h6 class="meta-title">Total:</h6>
          <span class="meta-info">{{ historyDetails?.meta?.amounts?.netAmount | currency: 'USD' }}</span>
        </div>
      </div>
    </div>
    <div class="stations">
      <div class="shipping-station" *ngIf="!!historyDetails?.shipmentInfo">
        <div class="brand">
          Shipping Station
        </div>
        <div class="info height-80">
          <h6 class="title">Shipping Method</h6>
          <p>{{ shippingMethod }}</p>
        </div>
        <div class="info">
          <h6 class="title">Tracking ID:</h6>
          <p>xxxxxxxxxxxxxxxxxxxxx</p>
        </div>
      </div>
      <div class="display">
        <button class="button-style">Re-print Label</button>
        <button class="button-style margin-20">Void Label</button>
      </div>
    </div>
  </div>
</div>
