import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { SearchData } from './core/models/index'
import { ROUTE_URLS } from './app-routing-names'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private content = new ReplaySubject<SearchData>(1)
  public sharedData = this.content.asObservable()
  constructor(private router: Router) {}
  public updateSearchData(searchData: SearchData) {
    this.content.next(searchData)
    this.router.navigate([ROUTE_URLS.SEARCH])
  }
}
