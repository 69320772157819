<div class="contact-container">
  <img src="{{ fullImgPath }}logoForContact.svg" alt="ztar-logo" class="ztar-logo" />
  <div class="desc">
    <h3 class="header">CONTACT US</h3>
    <p class="sub-header">Enter your information below so we csn get back to you</p>
  </div>
  <div class="card-container">
    <form (ngSubmit)="sendMessage($event)" [formGroup]="contactUsForm" class="contact-us-form">
      <section class="flex-section">
        <div class="flex-fields">
          <label class="label-head">Name *</label>
          <input type="text" id="name" placeholder="Enter Name" [(ngModel)]="name" formControlName="name" required />
          <label class="validation-message" *ngIf="contactUsForm.controls.name.touched && !name"> Name is required</label>
          <label class="validation-message" *ngIf="contactUsForm.controls.name.hasError('pattern')">Name is invalid</label>
        </div>
        <div class="flex-fields">
          <label class="label-head">Phone *</label>
          <input
            type="number"
            id="phone-number"
            placeholder="Enter Phone"
            formControlName="phone"
            [(ngModel)]="phone"
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
            required
          />
          <label class="validation-message" *ngIf="contactUsForm.controls.phone.touched && !phone"> Phone is required</label>
        </div>
      </section>
      <section class="flex-section">
        <div class="flex-fields top-padding">
          <label class="label-head">Email *</label>
          <input type="email" id="email" placeholder="Enter Email" [(ngModel)]="email" formControlName="email" required />
          <label class="validation-message" *ngIf="contactUsForm.controls.email.hasError('email')">Email is invalid</label>
          <label class="validation-message" *ngIf="contactUsForm.controls.email.touched && !email"> Email is required</label>
        </div>
        <div class="flex-fields msg-box top-padding">
          <label class="label-head">Message *</label>
          <textarea id="message" placeholder="Message" [(ngModel)]="message" formControlName="message" required maxlength="450"></textarea>
          <label class="validation-message" *ngIf="contactUsForm.controls.message.touched && !message"> Message is required </label>
        </div>
      </section>
    </form>
    <div>
      <re-captcha size="invisible" #invisible (resolved)="sendMessage($event)"></re-captcha>
      <button
        type="submit"
        id="submitButton"
        class="btn"
        [class.disabled]="!!isLoading || contactUsForm.invalid"
        [disabled]="!!isLoading || contactUsForm.invalid"
        (click)="invisible.execute()"
      >
        <img *ngIf="!!isLoading" src="{{ fullImgPath }}loading-gif-icon.gif" class="gif-proccess" />Submit
      </button>
    </div>
    <!-- <div class="back-link"><a [routerLink]="['/' + ROUTE_URLS.LOGIN]">Back to Log in</a></div> -->
  </div>
</div>
