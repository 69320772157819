import { Component, OnInit } from '@angular/core'
import { SearchData } from 'src/app/core/models'
import { SearchService } from 'src/app/search.service'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  public selectedDate = ''
  public data
  public minDate
  public maxDate
  public searchData: SearchData
  constructor(private date: SearchService) {}

  ngOnInit(): void {}
  changeDate($event) {
    this.data = $event
    this.minDate = new Date(this.data[`begin`]).toISOString()
    this.maxDate = new Date(this.data[`end`]).toISOString()
    this.searchData = {
      category: 'createdDateMin',
      name: 'createdDateMin',
      searchValue: `${this.minDate} ,createdDateMax:${this.maxDate}`,
    }
    sessionStorage.setItem('searchData', JSON.stringify(this.searchData))
    this.date.updateSearchData(this.searchData)
  }
}
