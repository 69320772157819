<div class="zendesk-container">
  <div class="file-ticket-container">
    <div class="file-ticket-list">
      <select required>
        <option value="" disabled selected>File a ticket</option>
      </select>
    </div>
    <a class="submit-link" (click)="createIssue()">Go</a>
  </div>
  <div *ngIf="!!showCreationIssueSection">
    <p class="header">Choose an issue</p>
    <form [formGroup]="fileTicketForm">
      <div class="file-ticket-list audience">
        <mat-form-field>
          <mat-label>Pick Audience</mat-label>
          <mat-select formControlName="selectedAudience" (ngModelChange)="selectAudienceOption($event)">
            <mat-option *ngFor="let audience of audienceOptions" [value]="audience.value">
              {{ audience.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!!selectedAudience">
        <div class="file-ticket-list category">
          <mat-form-field>
            <mat-label>Pick Category</mat-label>
            <mat-select formControlName="selectedCategory" (ngModelChange)="selectCategoryOption($event)">
              <mat-option *ngFor="let category of categoryOptions" [value]="category.value">
                {{ category.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="note-box">
          <textarea formControlName="note" id="noteText" placeholder="Add a note" class="text-box"></textarea>
        </div>
      </div>
    </form>
    <div class="button-container">
      <button
        class="btn-style"
        type="submit"
        (click)="fileAticket()"
        id="fileTicket"
        [disabled]="!!isLoading || fileTicketForm.invalid"
        [class.disabled]="!!isLoading || fileTicketForm.invalid"
      >
        File Ticket <img *ngIf="!!isLoading" src="{{ fullImgPath }}loading-gif-icon.gif" class="gif-process" />
      </button>
      <button class="btn-style left-margins" id="cancel" (click)="cancelIssue()">Cancel</button>
    </div>
  </div>
</div>
