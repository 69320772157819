<div class="search-section">
  <div class="options-flex-container" *ngIf="!!searchRequested && filteredOptions.length > 0">
    <div class="container-padding" *ngFor="let item of filteredOptions; let i = index">
      <div class="filtered-container">
        <b class="filtered-data">{{ item.name }}:&nbsp;{{ item.value }}</b
        ><a (click)="removeItem(i)"><img src="{{ fullImgPath }}ic-close-24-px.svg" class="close-icon"/></a>
      </div>
    </div>
    <div *ngIf="filteredOptions.length > 1">
      <mat-card>
        <mat-card-content>
          <mat-checkbox class="example-margin" [(ngModel)]="exactMatch" (change)="exactMatchChanged(exactMatch)">Exact Match</mat-checkbox>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div [class.loading-hidden-transparent]="!!loading"></div>
  <div class="no-result-section" *ngIf="!!searchRequested && dataSource?.filteredData.length < 1 && !loading">
    <p class="no-result">No results found</p>
  </div>
  <div *ngIf="!!searchRequested && dataSource?.filteredData.length > 0 && !loading" class="table-content">
    <div class="table responsive-table">
      <table>
        <thead>
          <tr>
            <th>Brand</th>
            <th>Order ID</th>
            <th>Customer</th>
            <th>Data & Time</th>
            <th>Status</th>
            <th>Shipping Method</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of dataSource?.filteredData | paginate: config">
            <td>{{ order.mvnoName }}</td>
            <td>{{ order.id }}</td>
            <td>{{ order.customerId }}</td>
            <td>{{ order.createdDate | date: 'MM-dd-yy' }}<br />{{ order.createdDate | date: 'HH:MM a' }}</td>
            <td
              [ngClass]="{
                'new-color': order.orderStatusCode == 'AWAITING_PAYMENT',
                'pending-color': order.orderStatusCode == 'AWAITING_FULFILLMENT',
                'processed-color': order.orderStatusCode == 'AWAITING_SHIPMENT',
                'digital-color': order.orderStatusCode == 'SVC_PURCHASED'
              }"
            >
              {{ order?.orderStatusTitle | titlecase }}
            </td>
            <td>{{ order.shipmentMethod }}</td>
            <td><button class="process-button" [routerLink]="['/orders/' + order.id]">Process</button></td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" *ngIf="dataSource?.filteredData?.length > 0">
        <app-pagination [config]="config" (configChange)="pageChanged($event)"></app-pagination>
      </div>
    </div>
  </div>
</div>
